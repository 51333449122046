import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  Login, ForgotPassword, SecurityCode, InvesterOpportunities,
  InvesterIndividual, Profile, Documents, NotFound, CreateNewPassword, 
  DealInvestmentOpportunity, HoldingDetails, ExpressIntrest, OpportunitySuccess,
  ResetPassword, MyHoldings, DocumentPermissions,
  EditProfile, EditEntity, AddJoinInvester, LegelData, SignupSuccess, PasswordSuccess, Terms, Privacy, ForgotPasswordSuccess
} from './Pages';

function App() {
  
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Login} />
        <Route exact path='/signup-success' component={SignupSuccess} />
        <Route exact path='/password-success' component={PasswordSuccess} />
        <Route exact path='/forgot-password-success' component={ForgotPasswordSuccess} />
        <Route exact path='/forgot' component={ForgotPassword} />
        <Route exact path="/resetPassword:id" component={ResetPassword} />
        <Route exact path="/createPassword:email" component={ResetPassword} />
        <Route exact path="/security" component={SecurityCode} />
        <Route path="/createNewPassword:email" component={CreateNewPassword} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/basic-info" component={LegelData} />
        <Route exact path="/edit-profile" component={EditProfile} />
        <Route exact path="/edit-joint-investor" component={AddJoinInvester} />
        <Route exact path="/edit-entity" component={EditEntity} />
        {/* investor */}
        <Route exact path='/investor-opportunities' component={InvesterOpportunities} />
        <Route exact path='/my-holdings' component={MyHoldings} />
        <Route exact path="/documents" component={Documents} />
        <Route exact path='/holding-details' component={HoldingDetails} />
        <Route exact path='/verify' component={ExpressIntrest} />
        <Route exact path='/success' component={OpportunitySuccess} />
        <Route exact path="/investor-types" component={InvesterIndividual} />
        <Route exact path="/deal-investment/:name?/:id" component={DealInvestmentOpportunity} />
        <Route exact path="/document-permissions" component={DocumentPermissions} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route path="*" component={NotFound} />

      </Switch>
    </BrowserRouter>
  );
}

//Exporting app
export default App; 

import React, { Component } from "react";

/* Antd library components */
import { Row, Col, Form, Button, Input, Modal, notification, Breadcrumb } from "antd";

/* Relative imports */
import { AppHeader, Footer, Loading } from "../../Components";
import "./DealInvestmentOpportunity.css";
import { getUserDataLean, getOpportunityDataById, generateBranchShareLink, updateProfile } from '../../Redux/Crud';
import { connect } from 'react-redux';
import { Strings } from '../../Constants/Strings';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from 'moment';

import { EditorState, convertFromRaw } from 'draft-js';

import { MailOutlined } from "@ant-design/icons";

/* Deal investment opportunity component */
class DealInvestmentOpportunity extends Component {
  formRef = React.createRef()
  formRef1 = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      offerData: null,
      overView: null,
      shares: 100,
      transactionVolume: 0,
      visible: false,
      linkUrl: null,
      offerId: null,
      loading: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log(this.props.location)
    console.log(this.props.location.search)
    var urlParams = new URLSearchParams(this.props.location.search)
    console.log(urlParams.get('?/:'))
    var urlData = decodeURIComponent(this.props.location.search).split("&")
    if(urlData.length > 0 && urlParams.has('code')){
      let data = {}
      data.code = urlParams.get('code')
      data.path = this.props.location.pathname+urlData[0]
      window.data = data
      this.setState({ offerId: urlData[0].split(":")[1] }, () => {
        this.getParticularOpportunitie()
      })
    }
    this.getUserDetails()
    if (this.props.location && this.props.location.search) {
      let data = this.props.location.search;

      if (data.split(":") && data.split(":")[1]) {
        // this.setState({ offerId: parseInt(data.split(":")[1]) }, () => {
          this.setState({ offerId: data.split(":")[1] }, () => {
          this.getParticularOpportunitie()
        })
      } else {
        setTimeout(() => {
          let data = window.data;
          if (data && data.path && data.offerId) {

            // this.setState({ offerId: parseInt(window.data.offerId) }, () => {
              this.setState({ offerId:window.data.offerId }, () => {
              window.data = null;
              window.branch.logout();
              this.getParticularOpportunitie()
            })
          }
        }, 1000)
      }
    }
    setTimeout(() => {
      let data = window.data;
      if (data && data.path && data.offerId) {

        // this.setState({ offerId: parseInt(window.data.offerId) }, () => {
          this.setState({ offerId: window.data.offerId }, () => {
          window.data = null;
          window.branch.logout();
          this.getParticularOpportunitie()
        })
      }
    }, 1000)
  }

  //getting user data
  getUserDetails() {
    if (localStorage.getItem('userData')) {
      let user = JSON.parse(localStorage.getItem('userData'));
      this.props.getUserDataLean(user.id)
        .then(res => {
          if (res.type === Strings.successType) {
            if (res.data && res.data.userType && res.data.userType === 2) {
              if (res.data && res.data.navigationPath && res.data.navigationPath !== "") {
                this.props.updateProfile({
                  userId: user.id,
                  data: { navigationPath: "" }
                })
              }
              this.setState({
                user: res.data
              })
            } else {
              localStorage.clear();
              this.props.history.push('/');
            }
          } else {
            localStorage.clear();
            this.props.history.push('/');
          }
        })
    } else {
      localStorage.clear();
      this.props.history.push('/');
    }
  }

  getParticularOpportunitie() {
    this.props.getOpportunityDataById(this.state.offerId).then((res) => {
      if (res.type === Strings.successType) {
        this.setState({
          offerData: res.data
        }, () => {
          window.data = null;
          localStorage.removeItem("tsfNavigation")
          let overView = res.data && res.data.companyDetails && res.data.companyDetails.overview && res.data.companyDetails.overview.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(res.data.companyDetails.overview.content))) : null;
          this.setState({ overView: overView });
          if (this.formRef.current) {
            let amount = (this.state.offerData && this.state.offerData.pricePerShare ? this.state.offerData.pricePerShare * this.state.shares : (this.state.offerData.pricePerUnit ? this.state.offerData.pricePerUnit * this.state.shares : 0));
            this.setState({
              transactionVolume: JSON.parse(amount.toFixed(2))
            })
            this.formRef.current.setFieldsValue({
              "shares": this.state.shares
            })
          }
        });
      }
    })
  }

  //handling form submit
  handleSubmit() {
    if (this.state.transactionVolume >= (this.state.offerData && this.state.offerData.minimumInvestment ? this.state.offerData.minimumInvestment : 5000)) {
      let obj = this.state.offerData;
      obj.transactionVolume = this.state.transactionVolume;
      obj.selectedShares = this.state.shares;
      this.props.history.push('/investor-types', { shareData: obj })
    } else {
      // this.showingMessage('error', Strings.transactionVolumeError);
      this.showingMessage('error', "Minimum Units must be greater than " + (this.state.offerData && this.state.offerData.minimumInvestment ? this.state.offerData.minimumInvestment : "5000"));
    }
  }


  //showing notification message
  showingMessage(type, message) {
    notification[type]({
      description: message,
    });
  }

  //slider on change event
  handleSliderChange(e) {
    if (e) {
      this.formRef.current.setFieldsValue({
        "shares": e
      })
      this.setState({ shares: e }, () => {
        let amount = (this.state.offerData && this.state.offerData.pricePerShare ? this.state.offerData.pricePerShare * e : 0);
        this.setState({
          transactionVolume: JSON.parse(amount.toFixed(2))
        })
      })
    }
  }

  // input on change event
  handelNumberOfShares = (e) => {
    if (e.target.value) {
      if (e.target.value > 10000) {
        e.target.value = 10000;
      }
      this.formRef.current.setFieldsValue({
        "shares": e.target.value
      })
      this.setState({ shares: e.target.value }, () => {
        let amount = (this.state.offerData && this.state.offerData.pricePerShare ? this.state.offerData.pricePerShare * e.target.value : (this.state.offerData.pricePerUnit ? this.state.offerData.pricePerUnit * e.target.value : 0));
        this.setState({
          transactionVolume: JSON.parse(amount.toFixed(2))
        })
      })
    } else {

      this.setState({
        shares: 0,
        transactionVolume: 0
      })

    }
  }

  // generateLink for share
  generateLink() {
    this.setState({ loading: true })
    let obj = {
      url: window.location.origin + "/deal-investment/:" + this.state.offerData.portfolioCompanyName + "?/:" + this.state.offerData.id,
      path: "/deal-investment/:" + this.state.offerData.portfolioCompanyName + "?/:" + this.state.offerData.id,
      opportunityId: this.state.offerData.id
    }
    this.props.generateBranchShareLink(obj).then((res) => {
      if (res.type === Strings.successType) {
        this.setState({
          loading: false,
          visible: true,
          linkUrl: res.url
        })
      } else {
        this.setState({ loading: false })
        this.showingMessage(Strings.errorType, res.message);
      }
    })
  }


  render() {
    return (
      // Deal Investment Opportunity
      <div className="deal-investment-section">
        <div key={this.state.user ? this.state.user : null}>
          <AppHeader history={this.props.history} user={this.state.user} />
          {this.state.loading ? <Loading></Loading> : null}
        </div>

        {/* ----Deal investment line item---- */}
        <div className="container ani-left-to-right" >
          {/* <h1 className="login-register-title-h investor-opportunity-title-h">Hello <span className="">{this.state.user ? (this.state.user.firstName + " " + this.state.user.lastName) : null}</span><br />Make The Right Investment</h1> */}

          <Breadcrumb separator="" key={this.state.offerData ? this.state.offerData : null}>
            <Breadcrumb.Item onClick={() => { this.props.history.push('/investor-opportunities') }}>Opportunities {`/`} </Breadcrumb.Item>
            <span>{this.state.offerData && this.state.offerData.portfolioCompanyName ? this.state.offerData.portfolioCompanyName : null}</span>
          </Breadcrumb>
          <div>
            <section>
              <Row gutter={[16, 16]} className="deal-lineitem">
                <Col sm={24} md={13} lg={17} className="company-details-section">
                  {this.state.offerData ? <div className="logo-content">
                    <div>
                      {this.state.offerData && this.state.offerData.logo && this.state.offerData.logo.orginalUrl ?
                        <img src={this.state.offerData.logo.orginalUrl} alt={this.state.offerData.logo.orginalUrl} className="deal-investor-company-logo" />
                        : null}
                    </div>
                    <div>
                      <h1 className="page-main-heading">{this.state.offerData && this.state.offerData.portfolioCompanyName ? this.state.offerData.portfolioCompanyName : null}</h1>

                      {this.state.offerData && this.state.offerData.companyDetails ?
                        <h4 className="company-name">{this.state.offerData.companyDetails.category ? this.state.offerData.companyDetails.category : null} {this.state.offerData.companyDetails.founded ? ' | ' + moment(new Date(this.state.offerData.companyDetails.founded)).format('YYYY') : null}{this.state.offerData.companyDetails.location ? ' | ' + this.state.offerData.companyDetails.location : null}</h4> : null}

                      {this.state.offerData && this.state.offerData.companyDetails && this.state.offerData.companyDetails.introOne ? <p className="about-company">{this.state.offerData.companyDetails.introOne}</p> :
                        null}
                    </div>
                    <Button className="primary-button" onClick={() => { this.generateLink() }}>Share With Friend</Button>
                  </div> : null}
                </Col>
                {this.state.user && this.state.offerData && (this.state.user.verifyStatus === 2 && this.state.offerData.isFullySubscribed !== true) ?
                  <Col sm={24} md={9} lg={4} className="price-col">
                    <div className="shares-form">

                      <Form layout="horizantal" initialValues={this.state.offerData} ref={this.formRef} >
                        <Row className="total-price-row-sec">
                          <Col sm={24} md={24} lg={24} className="caliculation-sec">
                            {this.state.offerData && this.state.offerData.pricePerShare ?
                              <Form.Item
                                name="shares"
                                label="No. of Shares:"
                              >

                                <Input type="number" onChange={this.handelNumberOfShares} onKeyDown={(evt) => (evt.key === '+' || evt.key === '.' || evt.key === '-' || evt.key === 'e') && evt.preventDefault()} prefix={<MailOutlined className="main-outlined" />} />

                              </Form.Item> :
                              <Form.Item
                                name="shares"
                                label="No. of Units:"
                              >

                                <Input type="number" onChange={this.handelNumberOfShares} onKeyDown={(evt) => (evt.key === '+' || evt.key === '.' || evt.key === '-' || evt.key === 'e') && evt.preventDefault()} prefix={<MailOutlined className="main-outlined" />} />

                              </Form.Item>

                            }
                          </Col>

                        </Row>
                        {this.state.offerData && this.state.offerData.pricePerShare ?
                          <>
                            <Row className="total-price-row-sec">
                              <Col sm={12} md={12} lg={15} className="price-sec">
                                <div className="share-price-label">Share Price: </div>
                              </Col>

                              <Col sm={12} md={12} lg={9} className="price-sec">
                                {this.state.offerData && this.state.offerData.pricePerShare ? <div className="total-price-div"> <span className="multile-dolor">$  </span><span className="share-price-text">{this.state.offerData.pricePerShare}</span></div> : <div className="total-price-div">Price per Share : $0</div>}
                              </Col>
                            </Row>
                          </> :
                          <Row className="total-price-row-sec">
                            <Col sm={12} md={12} lg={15} className="price-sec">
                              <div className="share-price-label">Unit Price: </div>
                            </Col>
                            <Col sm={12} md={12} lg={9} className="price-sec">
                              {this.state.offerData && this.state.offerData.pricePerUnit ? <div className="total-price-div"> <span className="multile-dolor">$  </span><span className="share-price-text">{this.state.offerData.pricePerUnit}</span></div> : <div className="total-price-div">Price per Unit : $0</div>}
                            </Col>
                          </Row>

                        }
                        {this.state.offerData && this.state.offerData.minimumShares && this.state.offerData.minimumShares.key ? <p className="subscription-amount">{this.state.offerData.minimumShares.key}{this.state.offerData.minimumShares.value} </p> : null}

                        <p className="subscription-amount">Subscription Amount:  <br /><label> ${this.state.transactionVolume.toLocaleString('USD')}</label></p>

                        <div className="shares-next-button">
                          <Button type="primary" disabled={this.state.user && this.state.user.verifyStatus === 2 ? false : true} onClick={() => {
                            this.handleSubmit()
                          }}>NEXT</Button>
                        </div>

                      </Form>
                    </div>
                  </Col> : null}
                {this.state.user && this.state.user.verifyStatus === 1 ?
                  <Col sm={24} md={9} lg={4} className="price-col">
                    <div className="shares-form top-shelf-note">
                      <div>
                        TopShelf team will get back to you with verification steps
                      </div>
                    </div>
                  </Col> : null
                }

              </Row>
            </section>
            <section className="company-detals-rich-sec">
              {this.state.overView ?
                <Col sm={24} md={13} lg={17} className="company-details-section">

                  <Editor placeholder={Strings.overviewPlaceholder}
                    toolbarHidden={true}
                    editorState={this.state.overView}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                  />
                </Col>
                : null}
            </section>
          </div>
          <Modal
            title="Copy Link"
            className="share-modal"
            visible={this.state.visible}
            onOk={() => {
              navigator.clipboard.writeText(this.state.linkUrl); this.setState({
                visible: false,
                linkUrl: null
              }, () => {
                this.showingMessage(Strings.successType, "Link copied to clipboard");
              })
            }}
            onCancel={() => {
              this.setState({
                visible: false,
                linkUrl: null
              })
            }}
            okText="Copy"
          >
            <p className="confirm-text">Are you sure to copy Link <a className="copy-link">{this.state.linkUrl} !</a></p>
          </Modal>
        </div>
        <Footer history={this.props.history} />
      </div>
    );
  }
}

export default connect(null, { getUserDataLean, getOpportunityDataById, generateBranchShareLink, updateProfile })(DealInvestmentOpportunity);

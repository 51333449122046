import React, { Component } from "react";
import "antd/dist/antd.css";
import { Loading } from '../../Components';

/* Antd library components */
import { Input, Form, Button, Row, Col, notification, Tabs, Select } from "antd";

/* Relative imports */
import { Strings } from "../../Constants/Strings";
import { Constants } from "../../Constants/Constants";
import { connect } from "react-redux";
import { login, signUp, getUserDataLean } from '../../Redux/Crud';
import { UserOutlined, MailOutlined, LockOutlined } from "@ant-design/icons";
import logo from '../../assets/images/logo.png';
import "./LoginRegister.css";
import ReCAPTCHA from "react-google-recaptcha";

const { TabPane } = Tabs;
const { Option } = Select;
//Declaring the Login component
class Login extends Component {
  formRef = React.createRef();
  formRef1 = React.createRef();
  recaptchaRef = React.createRef();
  recaptchaRef1 = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      phoneNumberObj: null,
      value: "hello",
      loading: false,
      showPassword: false
    };
  }

  componentDidMount() {
    this.getUserDetails()
  }

  //getting user data
  getUserDetails() {
    if (localStorage.getItem('userData')) {
      let user = JSON.parse(localStorage.getItem('userData'));
      this.props.getUserDataLean(user.id)
        .then(res => {
          if (res.type === Strings.successType) {
            if (res.data && res.data.userType && res.data.userType === 2) {
              this.props.history.push('/investor-opportunities');
            } else {
              localStorage.clear();
              this.props.history.push('/');
            }
          } else {
            localStorage.clear();
            this.props.history.push('/');
          }
        })
    } else {
      localStorage.clear();
      this.props.history.push('/');
    }
  }

  // login form submit event
  handleSubmit(values) {
    if (values) {
      this.setState({ loading: true });
      this.props.login(values).then(res => {
        if (res.type === Strings.successType) {
          if (res.data.userType === 1) {
            this.showingMessage('error', Strings.adminLoginError)
            this.recaptchaRef.current.reset();
            this.formRef1.current.setFieldsValue({ "recaptchaResponse": null });
            this.setState({ loading: false, showPassword: false });
          } else {
            this.showingMessage('success', Strings.loginSuccessMessage)
            localStorage.setItem('userData', JSON.stringify({
              id: res.data.id,
              accessToken: res.data.accessToken,
            }))
            if (window.data && window.data.path) {
              this.props.history.push(window.data.path);
              window.data = null;
            } else {
              if (res.data.navigationPath && res.data.navigationPath !== "") {
                this.props.history.push(res.data.navigationPath);
              } else if (res.data.verifyStatus) {

                if (res.data.verifyStatus === 1) {
                  this.props.history.push('/profile');
                } else if (res.data.verifyStatus === 2) {
                  this.props.history.push('/investor-opportunities');
                } else {
                  this.props.history.push("/edit-profile", { fromLogin: true });
                }
              } else {
                this.props.history.push("/edit-profile", { fromLogin: true });
              }
            }
            this.setState({ loading: false, showPassword: false });
          }
        }
        else {
          this.recaptchaRef.current.reset();
          this.formRef1.current.setFieldsValue({ "recaptchaResponse": null });
          this.showingMessage('error', res.message);
          this.setState({ loading: false });
        }
      })
    }
  };

  //handling form submit
  handleSignupSubmit(values) {
    this.setState({ loading: true, finalForm: values });
    // delete values.captcha
    values.url = window.location.origin;
    values.countryCode = this.state.phoneNumberObj.code;
    console.log(values)
    values.referralCode = "";
    values.navigationPath = "";
    // if (window.data && window.data["~referring_link"]) {
    //   let UrlData = window.data["~referring_link"].split("code=")
    //   if (UrlData && UrlData[1]) {
    //     values.referralCode = UrlData[1]
    //   }
    // }
    if (window.data && window.data['code']) {
        values.referralCode = window.data['code']
    }
    if (window.data && window.data.path) {
      values.navigationPath = window.data.path
    }
    this.props.signUp(values).then(res => {
      if (res.type === Strings.successType) {
        window.data = null;
        this.formRef.current.resetFields();
        this.props.history.push('/signup-success', { email: values.email });
        this.setState({ loading: false });
      } else {
        this.recaptchaRef1.current.reset();
        this.formRef.current.setFieldsValue({ "recaptchaResponse": null });
        this.showingMessage(Strings.errorType, res.message, Strings.signUpTitle)
        this.setState({ loading: false });
      }
    })
  }

  //showing notification message
  showingMessage(type, message) {
    notification[type]({
      description: message,
    });
  }

  onChangeInput(event) {
    this.setState({ show: false });
    let i = (Strings.countrysList).indexOf(event);
    if (i > -1) {
      let obj = Strings.countrysNubesr[i];
      console.log(obj)
      this.setState({ phoneNumberObj: obj }, () => {

        this.setState({ show: true });
      })
    }
  }


  render() {
    window.addEventListener('message', ({ origin, data }) => {
      console.log(origin)
      console.log(data)
    })

    return (
      <div className="login-register" id="login-register">
        {this.state.loading ? <Loading></Loading> : null}
        <div className="logo-mobile">
          <img src={logo} alt={logo} />
        </div>
        {/*---Sign up Section----- */}
        <section className="login-register-section">
          <h1 className="login-register-title-h">Choose <span className="">TopShelf</span> today <br />Make The Right Investment</h1>

          <div className="login-register-main">
            <Row
              gutter={[16, 48]}
              justify="center"
              className="login-sec-main"
            >
              <Col xs={24} md={12} lg={12} className="login-sec-main-col">
                <Tabs defaultActiveKey="1" centered>

                  <TabPane tab="Sign in" key="1" className="login-sec-main-col-tab">
                    <Form initialValues={{ remember: true }} ref={this.formRef1} spellCheck="false" layout="horizantal" onFinish={this.handleSubmit.bind(this)}>
                      <div className="form-section-only">
                        <Form.Item
                          name="email"
                          className="label-align"
                          label={Strings.emailTitle}
                          rules={[
                            {
                              required: true,
                              message: Strings.emailInputMessage
                            },
                            {
                              type: 'email',
                              message: Strings.invalidEmailMessage,
                            }
                          ]}
                        >
                          <Input
                            prefix={<MailOutlined className="" />}
                          />
                        </Form.Item>
                        <Form.Item
                          className="login-pssword ani-bottom-to-top"
                          name="password"
                          label={Strings.passwordTitle}
                          rules={[
                            {
                              required: true,
                              message: Strings.passwordInputMessage
                            },
                            {
                              min: 8,
                              message: Strings.passwordMinLength,
                            },
                            {
                              max: 16,
                              message: Strings.passwordMaxLength,
                            }
                          ]}
                        >
                          <Input
                            type="password"
                            prefix={<LockOutlined className="" />}
                          />
                        </Form.Item>

                        <p className="welcome-text forgot-text" onClick={() => { this.props.history.push('/forgot') }}><a >Forgot password?</a></p>
                        <div className="captcha-sec-login">
                          <Form.Item
                            name="recaptchaResponse"
                            className="label-align"
                            label=""
                            rules={[
                              {
                                required: true,
                                message: 'please verify your captch!'
                              },
                            ]}
                          >
                            <ReCAPTCHA ref={this.recaptchaRef}
                              sitekey={Constants.reCaptchaKey}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="btn-section">
                        <Button type="primary" htmlType="submit" className="login-btn">
                          Sign in
                        </Button>
                      </div>
                    </Form>
                  </TabPane>
                  <TabPane tab="Sign up" key="2" className="sign-up-sec-main-col-tab">
                    <Form spellCheck="false" layout="horizantal" ref={this.formRef} onFinish={this.handleSignupSubmit.bind(this)}>
                      <Row >

                        <Col xs={24} md={24} lg={24}>
                          {/* ----first name----- */}
                          <Form.Item
                            name="firstName"
                            label={Strings.firstNameTitle}
                            normalize={(value) => value.trim()}
                            rules={[
                              {
                                required: true,
                                message: Strings.firstNameInputMessage
                              },
                              {
                                min: 1,
                                message: Strings.firstNameInputMinLengthMessage,
                              },
                              {
                                max: 30,
                                message: Strings.firstNameInputMaxLengthMessage,
                              },
                            ]}
                          >
                            <Input
                              prefix={<UserOutlined className="" />}

                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={24} lg={24}>
                          <Form.Item
                            name="middleName"
                            label={Strings.middleNameTitle}
                            normalize={(value) => value.trim()}
                            rules={[
                              {
                                required: false,
                                message: Strings.middleNameInputMessage
                              },
                              {
                                min: 1,
                                message: Strings.middleNameInputMinLengthMessage,
                              },
                              {
                                max: 30,
                                message: Strings.middleNameInputMaxLengthMessage,
                              },
                            ]}
                          >
                            <Input
                              prefix={<UserOutlined className="" />}

                            />
                          </Form.Item>

                        </Col>
                        <Col xs={24} md={24} lg={24}>
                          <Form.Item
                            name="lastName"
                            label={Strings.lastNameTitle}
                            normalize={(value) => value.trim()}
                            rules={[
                              {
                                required: true,
                                message: Strings.lastNameInputMessage
                              },
                              {
                                min: 1,
                                message: Strings.lastNameInputMinLengthMessage,
                              },
                              {
                                max: 30,
                                message: Strings.lastNameInputMaxLengthMessage,
                              },
                            ]}
                          >
                            <Input
                              prefix={<UserOutlined className="" />}

                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={24} lg={24}>
                          <Form.Item
                            name="email"
                            label={Strings.emailTitle}
                            rules={[
                              {
                                required: true,
                                message: Strings.emailInputMessage
                              },
                              {
                                type: 'email',
                                message: Strings.invalidEmailMessage,
                              }
                            ]}
                          >
                            <Input
                              prefix={<MailOutlined className="" />}

                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={24} lg={24}>
                          <Form.Item
                            name="countryOfResidence"
                            label="Country of Residence"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Country of Residence"
                              }
                            ]}
                          >
                            {/* <Input
                              prefix={<UserOutlined className="" />}
                            /> */}
                            <Select
                              showSearch
                              placeholder="Country of Residence"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.onChangeInput.bind(this)}
                            >
                              {Strings.countrysList.map((item, index) => {
                                return <Option key={index} value={item}>{item}</Option>
                              })}
                            </Select>
                          </Form.Item>

                        </Col>
                        <Col sm={24} md={24} lg={24} xs={24}>
                          {this.state.show ? <Form.Item
                            className="number-section"
                            name="phoneNumber"
                            label="Phone Number"
                            rules={[
                              {
                                required: true,
                                message: Strings.phoneNumberInputMessage
                              },
                              {
                                pattern: new RegExp("^[0-9]{" + this.state.phoneNumberObj.length + "}$"),
                                message: "Phone Number must contain " + this.state.phoneNumberObj.length + " digits"

                              }
                            ]}
                          >
                            <Input
                              prefix={<MailOutlined className="" />}
                            />
                          </Form.Item> :
                            <div />
                          }
                        </Col>
                        <Col sm={24} md={24} lg={24} xs={24} className="recapcha-signup">
                          <Form.Item
                            name="recaptchaResponse"
                            rules={[
                              {
                                required: true,
                                message: 'please verify your captch!'
                              },
                            ]}
                          >
                            <ReCAPTCHA ref={this.recaptchaRef1}
                              sitekey={Constants.reCaptchaKey}
                            />
                          </Form.Item>
                        </Col>
                      </Row>


                      <div className="btn-section">
                        <Button type="primary" htmlType="submit" className="login-btn">
                          Sign up
                        </Button>
                      </div>

                    </Form>
                  </TabPane>
                </Tabs>

              </Col>
              <Col xs={22} md={12} lg={12} className="login-sec-main-col-img">
                <div className="login-signup-card-text">
                  <div className="">
                    <h2>Discover Opportunities and<br />Track your holdings.</h2>
                    <h6>Explore the best investment opportunities.</h6>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    );
  }
}

// export default Login
export default connect(null, { login, signUp, getUserDataLean })(Login)


import React, { Component } from 'react'
import { Row, Col, notification, Steps, Table, Space } from 'antd';

import { AppHeader, Loading, Footer } from '../../Components';
import '../../App.css';
import './Documents.css';
import '../InvesterIndividual/InvesterIndividual.css';
import { connect } from 'react-redux';
import { Strings } from '../../Constants/Strings';
import { getUserDataLean, getSignedUrls, getUserDocuments, getDocumentsByUserId } from '../../Redux/Crud';
import moment from 'moment';


const { Step } = Steps;
class Documents extends Component {
    formRef = React.createRef();
    formRef1 = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            actualUser: null,
            step: 0,
            documentsList: [],
            sdDocumentsList: [],
            sdTotalCount: 0,
            w9totalCount: 0,
            w9DocumentsList: [],
            totalCount: 0,
            skip: 0,
            searchKey: Strings.documentTypesList[0],
            limit: Strings.noOfOrdersPerPage,
            current: 1,
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserDetails();
    }

    //getting user data
    getUserDetails() {
        if (localStorage.getItem('userData')) {
            let user = JSON.parse(localStorage.getItem('userData'));
            this.props.getUserDataLean(user.id)
                .then(res => {
                    if (res.type === Strings.successType) {
                        if (res.data && res.data.userType && res.data.userType === 2) {
                            this.setState({
                                actualUser: res.data
                            });
                            this.getSubScriptionDocuments(this.state.skip, this.state.limit)
                        } else {
                            localStorage.clear();
                            this.props.history.push('/');
                        }
                    } else {
                        localStorage.clear();
                        this.props.history.push('/');
                    }
                })
        } else {
            localStorage.clear();
            this.props.history.push('/');
        }
    }

    // get user documents
    getdocumentsList(skip, limit) {
        this.setState({ loading: true });
        let user = JSON.parse(localStorage.getItem('userData'));
        this.props.getUserDocuments({
            userId: user.id,
            categoryType: this.state.searchKey,
            skip: skip,
            limit: limit
        }).then((res) => {
            if (res.type === Strings.successType) {
                if (res.data && res.data.data) {
                    this.setState({
                        documentsList: res.data.data,
                        totalCount: res.data.totalCount,
                        loading: false
                    });
                } else {
                    this.setState({
                        documentsList: [],
                        totalCount: 0,
                        loading: false
                    });
                }
            } else {
                this.setState({
                    documentsList: [],
                    totalCount: 0,
                    loading: false
                });
            }
        })
    }

    //getSubScription Documents
    getSubScriptionDocuments(skip, limit) {
        this.setState({ loading: true });
        let user = JSON.parse(localStorage.getItem('userData'));
        this.props.getDocumentsByUserId({
            userId: user.id,
            skip: skip,
            limit: limit
        }).then((res) => {
            if (res.type === Strings.successType) {
                if (res.data && res.data.data) {
                    this.setState({
                        sdDocumentsList: res.data.data,
                        sdTotalCount: res.data.totalCount,
                        loading: false
                    });
                } else {
                    this.setState({
                        sdDocumentsList: [],
                        sdTotalCount: 0,
                        loading: false
                    });
                }
            } else {
                this.setState({
                    documentsList: [],
                    totalCount: 0,
                    loading: false
                });
            }
        })
    }

    getW9Documents(skip, limit) {
        this.setState({ loading: true });
        let user = JSON.parse(localStorage.getItem('userData'));
        this.props.getUserDocuments({
            userId: user.id,
            categoryType: this.state.searchKey,
            skip: skip,
            limit: limit
        }).then((res) => {
            if (res.type === Strings.successType) {
                if (res.data && res.data.data) {
                    this.setState({
                        w9DocumentsList: res.data.data,
                        w9totalCount: res.data.totalCount,
                        loading: false
                    });
                } else {
                    this.setState({
                        w9totalCount: 0,
                        w9DocumentsList: [],
                        loading: false
                    });
                }
            } else {
                this.setState({
                    w9totalCount: 0,
                    w9DocumentsList: [],
                    loading: false
                });
            }
        })
    }

    //on changing page  
    handleTableChange = (pagination) => {
        this.setState({
            current: pagination.current,
            skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
            limit: pagination.pageSize
        });
        if (this.state.step === 0) {
            this.getSubScriptionDocuments(
                (pagination.current * pagination.pageSize) - pagination.pageSize,
                pagination.pageSize
            );
        } else if (this.state.step === 4) {
            this.getW9Documents(
                (pagination.current * pagination.pageSize) - pagination.pageSize,
                pagination.pageSize
            );
        } else {
            this.getdocumentsList(
                (pagination.current * pagination.pageSize) - pagination.pageSize,
                pagination.pageSize
            );
        }
    }


    // getSignedUrl
    getSignedUrl(val) {
        this.setState({ loading: true });
        this.props.getSignedUrls({
            key: val,
            from: "user"
        }).then((res) => {
            if (res.type === Strings.successType) {
                this.setState({ loading: false });
                window.open(res.data, '_blank')
            } else {
                this.setState({ loading: false });
            }
        })
    }

    //showing notification message
    showingMessage(type, message, title) {
        notification[type]({
            description: message,
        });
    }




    handleDocuments(val) {
        this.setState({ loading: true });
        this.props.getSignedUrls({
            key: val,
            from: "documents"
        }).then((res) => {
            if (res.type === Strings.successType) {
                this.setState({ loading: false });
                window.open(res.data, '_blank')
            } else {
                this.setState({ loading: false });
            }
        })
    }

    changeStep(index) {

        this.setState({ step: index, searchKey: Strings.documentTypesList[index], skip: 0, current: 1 }, () => {
            if (index === 0) {
                this.getSubScriptionDocuments(this.state.skip, this.state.limit, this.state.searchKey);
            } else if (index === 4) {
                this.getW9Documents(this.state.skip, this.state.limit, this.state.searchKey);
            } else {
                this.getdocumentsList(this.state.skip, this.state.limit, this.state.searchKey);
            }

        })
    }


    render() {

        const columns = [
            {
                title: 'Document Name',
                key: 'id',
                render: (text, record) => {
                    // return record.documentPath
                    if (record.defaultPath) {
                        let value = record.defaultPath.split("/")
                        return value[value.length - 1]
                    } else if (record.documentPath) {
                        let value = record.documentPath.split("/")
                        return value[value.length - 1]
                    } else {
                        return ''
                    }
                }
            },

            {
                title: 'Document Type',
                key: 'id',
                render: (text, record) => {
                    // return record.documentPath
                    if (record.entityDetails && record.entityDetails.entityName) {
                        return "Entity (" + (record.entityDetails.entityName) + ")"
                    } else {
                        return "Individual"
                    }
                }
            },

            {
                title: 'Document Uploaded',
                key: 'id',
                render: (text, record) => {
                    // return record.documentPath
                    if (record.createdAt) {
                        return moment(new Date(record.createdAt)).format('MM/DD/YYYY')
                    }
                }
            },

            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <>
                        {/* <Space size="middle" onClick={() => { this.handleDocuments(record.documentPath); }}>
                            <a>Preview&</a>
                        </Space> */}
                        <Space size="middle" onClick={() => { this.handleDocuments(record.documentPath); }}>
                            {/* <a>Invite {record.name}</a> */}
                            <a>Download</a>
                        </Space>
                    </>
                ),
            },

        ]

        const columns1 = [
            {
                title: 'CompanyName',
                key: 'id',
                render: (text, record) => {
                    // return record.documentPath
                    if (record.opportunityDetails && record.opportunityDetails.portfolioCompanyName) {
                        return record.opportunityDetails.portfolioCompanyName
                    } else {
                        return ""
                    }
                }
            },
            {
                title: 'InvestorType',
                key: 'id',
                render: (text, record) => {
                    if (record.investorTypeId === 1) {
                        return 'Individual' + (record.jointInvestorId ? '(Joint)' : '')
                    } else if (record.investorTypeId === 2) {
                        return 'Entity'
                    } else if (record.investorTypeId === 3) {
                        return 'IRA'
                    }
                }
            },
            {
                title: 'Documents Uploaded',
                key: 'id',
                render: (text, record) => {
                    if (record.documents && record.documents.length > 0) {
                        if (record.documents[1] && record.documents[1].createdAt) {
                            return moment(new Date(record.documents[1].createdAt)).format('MM/DD/YYYY')
                        } else {
                            return ''
                        }
                    } else {
                        return ''
                    }
                }
            },
            { /*
                title: 'Anvil Documents',
                key: 'id',
                render: (text, record) => {
                    if (record.documents && record.documents.length > 0) {
                        if (record.documents[0]) {
                            let val = record.documents[0].defaultPath.split("/")
                            return <span className="documents-td" onClick={() => { this.handleDocuments(record.documents[0].filePath); }}>{val[val.length - 1]}</span>
                        } else {
                            return ''
                        }
                    } else {
                        return ''
                    }
                }
            */},
            {
                title: 'Subscription Documents',
                key: 'id',
                render: (text, record) => {
                    if (record.documents && record.documents.length > 0) {
                        if (record.documents[1]) {
                            let val = record.documents[1].defaultPath.split("/")
                            return <span className="documents-td" onClick={() => { this.handleDocuments(record.documents[1].filePath); }}>{val[val.length - 1]}</span>
                        } else {
                            return ''
                        }
                    } else {
                        return ''
                    }
                }
            }
        ]

        const columns2 = [
            {
                title: 'Document Type',
                key: 'id',
                render: (text, record) => {
                    // return record.documentPath
                    if (record.entityDetails && record.entityDetails.entityName) {
                        return "Entity (" + (record.entityDetails.entityName) + ")"
                    } else {
                        return "Individual"
                    }
                }
            },
            {
                title: 'Documents Uploaded',
                key: 'id',
                render: (text, record) => {
                    if (record.documents && record.documents.length > 0) {
                        if (record.documents[1] && record.documents[1].createdAt) {
                            return moment(new Date(record.documents[1].createdAt)).format('MM/DD/YYYY')
                        } else {
                            return ''
                        }
                    } else {
                        return ''
                    }
                }
            },
            {/*
                title: 'Anvil Documents',
                key: 'id',
                render: (text, record) => {
                    if (record.documents && record.documents.length > 0) {
                        if (record.documents[0]) {
                            let val = record.documents[0].defaultPath.split("/")
                            return <span className="documents-td" onClick={() => { this.handleDocuments(record.documents[0].documentPath); }}>{val[val.length - 1]}</span>
                        } else {
                            return ''
                        }
                    } else {
                        return ''
                    }
                }
            */},
            {
                title: 'Subscription Documents',
                key: 'id',
                render: (text, record) => {
                    if (record.documents && record.documents.length > 0) {
                        if (record.documents[1]) {
                            let val = record.documents[1].defaultPath.split("/")
                            return <span className="documents-td" onClick={() => { this.handleDocuments(record.documents[1].documentPath); }}>{val[val.length - 1]}</span>
                        } else {
                            return ''
                        }
                    } else {
                        return ''
                    }
                }
            }

        ]


        return (

            <div className="profile-main" key={this.state.actualUser ? this.state.actualUser : null} id="profile-main-section">
                {this.state.loading ? <Loading></Loading> : null}
                <div className="header-main" key={this.state.actualUser ? this.state.actualUser : null}>
                    <AppHeader history={this.props.history} user={this.state.actualUser} />
                </div>
                <div className="container">
                    <h1 className="login-register-title-h profile-title-h">Hello <span className="">{this.state.actualUser ? (this.state.actualUser.firstName + " " + this.state.actualUser.lastName) : null}</span><br />Make The Right Investment</h1>

                    {/* <Breadcrumb separator=">">
                        <Breadcrumb.Item >Account  {`/`} </Breadcrumb.Item>
                        {this.state.step === 0 ?
                            <span >Personal details</span> : null}
                        {this.state.step === 1 ?
                            <span>Documents</span> : null}
                        {this.state.step === 2 ?
                            <span>Entities</span> : null}
                    </Breadcrumb> */}

                    <Row gutter={[16]} className="invest-main-row-sec" justify="center">
                        <Col sm={24} md={14} lg={19}>
                            <div>
                                <div className="document-type-card">
                                    <div className="card-main" id="steps-investor-individual">
                                        <Steps size="small" type="navigation"
                                            current={this.state.step}  >
                                            {Strings.documentTypesList && Strings.documentTypesList.length > 0 ? Strings.documentTypesList.map((documentTypesList, index) => {
                                                return (
                                                    <Step title={documentTypesList} onClick={() => { this.changeStep(index) }} value={index} />
                                                )

                                            }) : null}
                                        </Steps>

                                        {this.state.step === 0 ?
                                            <Table
                                                key={(record) => { return record.id }} columns={columns1}
                                                dataSource={this.state.sdDocumentsList} rowKey={(record) => { return record.id }}
                                                onChange={this.handleTableChange}
                                                pagination={{
                                                    current: this.state.current,
                                                    pageSize: Strings.noOfOrdersPerPage,
                                                    total: this.state.sdTotalCount,
                                                    showSizeChanger: false,
                                                }} />
                                            : null}
                                        {this.state.step === 4 ?
                                            <Table
                                                key={(record) => { return record.id }} columns={columns2}
                                                dataSource={this.state.w9DocumentsList} rowKey={(record) => { return record.id }}
                                                onChange={this.handleTableChange}
                                                pagination={{
                                                    current: this.state.current,
                                                    pageSize: Strings.noOfOrdersPerPage,
                                                    total: this.state.w9totalCount,
                                                    showSizeChanger: false,
                                                }} /> : null}
                                        {this.state.step === 1 || this.state.step === 2 || this.state.step === 3 ?
                                            <Table
                                                key={(record) => { return record.id }} columns={columns}
                                                dataSource={this.state.documentsList} rowKey={(record) => { return record.id }}
                                                onChange={this.handleTableChange}
                                                pagination={{
                                                    current: this.state.current,
                                                    pageSize: Strings.noOfOrdersPerPage,
                                                    total: this.state.totalCount,
                                                    showSizeChanger: false,
                                                }} /> : null}

                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div >
                <Footer history={this.props.history} />
            </div >
        )
    }
}

//in this state we have all data which disptched functions called & mapping to props
const mapStateToProps = state => {
    return {
        user: state.investors.loggedUserDetails ? state.investors.loggedUserDetails : {},
    }
};

//exporting profile page
export default connect(mapStateToProps, { getSignedUrls, getUserDocuments, getUserDataLean, getDocumentsByUserId })(Documents);

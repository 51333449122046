import axios from 'axios';
import { Constants } from '../Constants/Constants.js';
import { Strings } from '../Constants/Strings.js';

const URL = Constants.baseURL;

/*------------------------------------- USERS -------------------------------*/

// login
export function login(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/users/login', queryParams)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return { type: Strings.errorType, message: "My Error" }
                }
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage }
            })
    }
}

// checkOtp
export function checkOtp(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/users/mobileNumberAuth', queryParams)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return { type: Strings.errorType, message: Strings.networkErrorMessage }
                }
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage }
            })
    }
}

// get user data by email
export function getUserByUserEmail(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/users/getUserByUserEmail', queryParams)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return { type: Strings.errorType, message: Strings.networkErrorMessage }
                }
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage }
            })
    }
}

//signup
export function signUp(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/users/registration', queryParams)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return { type: Strings.errorType, message: Strings.networkErrorMessage }
                }
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage }
            })
    }
}

// get user data by email
export function getInvitedUserByEmail(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/invited-users/getInvitedUserByEmail', queryParams)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return { type: Strings.errorType, message: Strings.networkErrorMessage }
                }
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage }
            })
    }
}

// get user data by email
export function completeInvitation(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/invited-users/completeInvitation', queryParams)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return { type: Strings.errorType, message: Strings.networkErrorMessage }
                }
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage }
            })
    }
}


//forgot password 
export function forgotPassword(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/users/forgotPassword', queryParams)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return { type: Strings.errorType, message: Strings.networkErrorMessage }
                }
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage }
            })
    }
}

// create password
export function setNewPassword(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/users/setNewPassword', queryParams)
            .then(response => {
                if (response.data) {
                    return response.data;
                } else {
                    return { type: Strings.errorType, message: Strings.networkErrorMessage }
                }
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage }
            })
    }
}

//update password
export function resetUserPassword(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/users/resetPassword', queryParams)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

//Sateesh added this code
export function getUserDataLean(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.get(`${URL}` + '/users/getUserByUserIdLean/' + queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage };
            })
    }

}

export function getUserDataProfile(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.get(`${URL}` + '/users/getUserByIdProfileLean/' + queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage };
            })
    }

}

//update user data
export function updateProfile(queryParams) {
    console.log(queryParams, 135)
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/users/updateUserByUserId', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage };
            })
    }
}

// check email link expiry
export function checkMailLinkExpiry(queryParams) {
    return dispatch => {
        return axios.post(`${URL}` + '/users/checkMailLinkExpiry', queryParams)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

export function userLogout(id) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.get(`${URL}` + '/users/logout/' + id, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}


/*------------------------------------- UPLOADS -------------------------------*/

//upload images
export function uploadImage(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/uploadImage', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage };
            })
    }
}

//upload files
export function uploadSecureFiles(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/uploadSecuredDocuments', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage };
            })
    }
}

//get files
export function getSignedUrls(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/getSignedUrl', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage };
            })
    }
}


/*------------------------------------- OPPORTUNITIES -------------------------------*/

// get '/api/opportunities/getByOpportunityId/{opportunityId}'
export function getOpportunityDataById(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.get(`${URL}` + '/opportunities/getByOpportunityId/' + queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage };
            })
    }
}


//get Opportunities  
export function getActiveOpportunitiesLean(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(URL + '/opportunities/getActiveOpportunitiesLean', queryParams, Authorization)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return dispatch({ type: 'ERROR', payload: { data: [], totalCount: 0 } });
                }
            })
            .catch(err => {
                return dispatch({ type: 'ERROR', payload: { data: [], totalCount: 0 } });
            })
    }
}

// invite user with branch
export function generateBranchShareLink(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/opportunities/inviteUser', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }

}






/*------------------------------------- EXPRESS-INTERESTS -------------------------------*/ 

//add express intrest
export function addExpressInterest(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/express-interests/addNewExpressInterest', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage };
            })
    }
}



// get particular investor data
export function getParticularUserHoldingswithStatus(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/express-interests/getByParticularInvestorId', queryParams, Authorization)
            .then(response => {
                return dispatch({ type: 'GET_MY_HOLDINGS', payload: response.data.data });
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

// get particular express interest by id Lean --Sateesh
export function getByExpressInterestIdLean(id) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.get(`${URL}` + '/express-interests/getByExpressInterestIdLean/' + id, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

//update expressInterest data
export function updateDataByExpressInterestId(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/express-interests/updateDataByExpressInterestId', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

export function generateSubscriptionAgreementUrl(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/express-interests/generateSubscriptionAgreementUrl', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

export function getDocumentsByUserId(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/express-interests/getDocumentsByUserId', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}



/*------------------------------------- INVESTOR-ENTITIES -------------------------------*/ 

// add new investor entity
export function addNewInvestorEntity(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/investor-entities/addNewInvestorEntity', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

// update investor entity
export function updateDataByInvestorEntityId(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/investor-entities/updateDataByInvestorEntityId', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

//get all entitys by investor Id
export function getAllByInvestorId(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/investor-entities/getAllByInvestorId', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

/*------------------------------------- NOTIFICATIONS -------------------------------*/ 

// create notification
export function addNewNotification(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/notifications/addNewNotification', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

// get all notifications by receiverId
export function getAllNotificationsByReceiverId(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/notifications/getAllByReceiverId', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}


// update notifications
export function updateNotificationsById(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/notifications/updateDataByNotificationId', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}



/*------------------------------------- JOINT INVESTORS -------------------------------*/

// add new joint investor
export function addNewJointInvestor(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/joint-investors/addNewJointInvestor', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

// update joint investor
export function updateDataByJointInvestorById(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/joint-investors/updateDataByJointInvestorId', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

//get all joint investors by investor Id
export function getAllJointInvestorsByInvestorId(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/joint-investors/getAllByInvestorId', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}


/*------------------------------------- USER DOCUMENTS -------------------------------*/

//get user Documents  
export function getUserDocuments(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(URL + '/user-documents/getByUserId', queryParams, Authorization)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return dispatch({ type: 'ERROR', payload: { data: [], totalCount: 0 } });
                }
            })
            .catch(err => {
                return dispatch({ type: 'ERROR', payload: { data: [], totalCount: 0 } });
            })
    }
}

/*------------------------------------- QUESTIONNAIRE -------------------------------*/

//get all joint investors by investor Id
export function addNewQuestionnaire(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/questionnaire/addNewQuestionnaire', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

//
export function updateQuestionnaire(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/questionnaire/updateDataById', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}

export function getLatestQuestionnaire(id) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.get(`${URL}` + '/questionnaire/getLatestQuestionnaire/' + id, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}


/*------------------------------------- QUESTIONS -------------------------------*/

export function getAllQuestionsByFilters(queryParams) {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.post(`${URL}` + '/questions/getAllQuestionsByFilters', queryParams, Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}


/*------------------------------------- CLOSING DOCUMENTS PROVISIONS -------------------------------*/

export function getAllClosingDocumentsProvisions() {
    const Authorization = { headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).accessToken } };
    return dispatch => {
        return axios.get(`${URL}` + '/closing-documents-provisions/getAllClosingDocumentsProvisions', Authorization)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                return { type: Strings.errorType, message: Strings.networkErrorMessage, data: [] };
            })
    }
}



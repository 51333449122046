import React, { Component } from 'react'
import { Row, Col, Radio, Button, notification, Modal } from 'antd';
import { PlusSquareFilled, EditOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import { Strings } from '../../Constants/Strings';
import { Constants } from '../../Constants/Constants';
import {  getAllByInvestorId, updateDataByInvestorEntityId, uploadImage, uploadSecureFiles, getSignedUrls } from '../../Redux/Crud';
import AnvilSignatureFrame from '@anvilco/react-signature-frame'


class AddEntity extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            user: null,
            showAddEntity: false,
            disableCheckBox: false,
            checkBoxStatus: false,
            mailingFormDisable: true,
            showMailingSelect: false,
            showPrimarySelect: false,
            entitys: [],
            checkedEntity: null,
            editEntityValue: null,
            selectedEntityId: null,
            initialValues: null,
            shareData: null,
            isShowModal: false,
            anvilUrl: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getAllEntitys();
        this.setState({
            user: this.props.user
        })
        if (this.props.shareData) {
            this.setState({
                shareData: this.props.shareData
            })
        }
    }

    // submit express intrest
    formSubmit() {
        if (this.state.checkedEntity) {
            let obj = {
                id: 2,
                entityId: this.state.checkedEntity.id
            }
            this.props.submitForm(obj)
        } else {
            this.showingMessage(Strings.errorType, Strings.entityNotSelectedError);
        }
    }

    // get user entitys list
    getAllEntitys() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.props.getAllByInvestorId({
            "investorId": user.id,
            "skip": 0,
            "limit": 0
        }).then(res => {
            if (res.type === Strings.successType) {
                let entitys = res.data.data
                this.setState({
                    entitys: entitys
                }, () => {
                    if (entitys[0]) {
                        entitys[0].checked = true;
                    }
                    this.setState({
                        entitys: entitys,
                        checkedEntity: entitys[0]
                    })
                });
            }
        })
    }


    //showing notification message
    showingMessage(type, message, title) {
        notification[type]({
            description: message,
        });
    }

    // change event for select entity
    handleRadioButtonChange(ind) {
        let entitys = this.state.entitys
        entitys.map(item => {
            item.checked = false;
        })
        entitys[ind].checked = true;
        this.setState({
            entitys: entitys,
            checkedEntity: entitys[ind]
        })
    }

    // edit entity
    editEntity(item) {
        this.props.history.push('/edit-entity', { editEntityValue: item, initialValues: item, shareData: this.state.shareData })
    }


    // add entity
    AddEntityForm() {
        this.props.history.push('/edit-entity', { editEntityValue: null, initialValues: null, shareData: this.state.shareData })
    }

    openSubmitW9(item){
        let Url = Constants.anvilBaseUrl + (item && item.w9FormCode ? item.w9FormCode : null) + "/"
         this.setState({anvilUrl: Url, selectedEntityId: item.id}, ()=>{                              
            this.setState({ isShowModal: true })
         })
    }

    updateW9Status() {
        this.setState({ loading: true });
        this.props.updateDataByInvestorEntityId({
            investorEntityId: this.state.selectedEntityId,
            data: { isW9FormFilledUp: true }
        }).then(res => {
            if (res.type === Strings.successType) {
                this.showingMessage(Strings.successType, res.message, Strings.profileTitle);
                this.getAllEntitys()
                this.setState({ loading: false, isShowModal: false, selectedEntityId: null });
            } else {
                this.showingMessage(Strings.errorType, res.message, Strings.profileTitle)
                this.setState({ loading: false });
            }
        })
    }

    render() {
        const { express, user } = this.props;
        this.state.user = user;
        return (

            <div className="profile-main" key={this.props.user ? this.props.user : null}>
                <h2 className="join-investor-heading" id="entity-heading-label">Entites <span>(Optional)</span></h2>
                <div className="profile-section">
                    <Row gutter={[16]} className="entity-section">
                        {this.state.entitys && this.state.entitys.length > 0 ? this.state.entitys.map((item, ind) => {
                            return <Col sm={24} md={12} lg={8}>
                                <div className={item && item.isW9FormFilledUp ? "entity-main": "entity-main-active"} >
                                    <div className="entity-id" id="entity-id">
                                        {/* Entity:{ind + 1} */}

                                        <div className="entity-investor-name">{item.entityName}</div>
                                        {express ? <div className="entity-check-radio-sec"><Radio checked={item.checked} onChange={() => { this.handleRadioButtonChange(ind) }}></Radio></div> :
                                            <p onClick={() => { this.editEntity(item) }}>
                                                <EditOutlined className="edit-icon" /></p>
                                        }


                                    </div>
                                    <div > {item.firstName + " " + (item.middleName ? item.middleName + " " : "") + item.lastName}</div>

                                    {/* <div className="">{item.entityName} <span> <span>{item.taxId}</span></span></div> */}
                                    <div>{item.email}</div>
                                    <div> {item.countryCode + " " + item.phoneNumber}</div>
                                    {item && item.isW9FormFilledUp ? null :

                                        <div onClick={() => { this.openSubmitW9(item) }} className="legal-info">
                                            Submit form W9
                                        </div>}
                                </div>
                            </Col>


                        }) : null}
                        <Col sm={24} md={12} lg={8} className="add-entity-button">
                            <Button type="" icon={<PlusSquareFilled />} onClick={() => { this.AddEntityForm() }}>
                                Add Entity
                            </Button>
                        </Col>
                    </Row>
                </div>
                {express ?
                    <div className="back-complete-btn">
                        <Button type="primary" onClick={() => { this.formSubmit() }} className="primary-button">PROCEED</Button>
                    </div>
                    : null}

                <Modal className="modal-form-sec entity-modal" title="Upload Documents" visible={this.state.isShowModal}
                    onCancel={() => {
                        this.setState({ isShowModal: false })
                    }} footer={null}>
                    <AnvilSignatureFrame
                        signURL={this.state.anvilUrl}
                        scroll="smooth"
                        onFinishSigning={(payload) => {
                            if (payload && payload.action === "signerComplete") {
                                this.updateW9Status()
                            }
                        }}
                        onError={(errorPayload) => console.log(errorPayload)}
                    />
                </Modal>

            </div >
        )
    }
}


//exporting page
export default connect(null, { getAllByInvestorId, updateDataByInvestorEntityId, uploadImage, uploadSecureFiles, getSignedUrls })(AddEntity);

import React, { Component } from 'react';
import { notification } from 'antd';
import cardImage from "../../assets/images/cardImage.png";
import moment from 'moment';
import { connect } from 'react-redux';
import { getUserDataLean, generateSubscriptionAgreementUrl, updateDataByExpressInterestId } from '../../Redux/Crud';
import { AppHeader, Footer, Loading } from '../../Components';
import '../../App.css';
import './OpportunitySuccess.css';
import { Strings } from '../../Constants/Strings';
import AnvilSignatureFrame from '@anvilco/react-signature-frame'


class OpportunitySuccess extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user: null,
            holdingData: null,
            anvilUrl: null,
            isShowModal: false,
            loading: false,
            isShowComplete: false,

        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserDetails();
        this.setState({
            holdingData: this.props.location.state.holdingData
        }, () => {
            this.generateSubscriptionAgreement()
        });
    }

    //getting user data
    getUserDetails() {
        if (localStorage.getItem('userData')) {
            let user = JSON.parse(localStorage.getItem('userData'));
            this.props.getUserDataLean(user.id)
                .then(res => {
                    if (res.type === Strings.successType) {
                        if (res.data && res.data.userType && res.data.userType === 2) {
                            this.setState({
                                user: res.data
                            })
                        } else {
                            localStorage.clear();
                            this.props.history.push('/');
                        }
                    } else {
                        localStorage.clear();
                        this.props.history.push('/');
                    }
                })
        } else {
            localStorage.clear();
            this.props.history.push('/');
        }
    }

    generateSubscriptionAgreement() {
        this.setState({ loading: true });
        this.props.generateSubscriptionAgreementUrl({ expressInterestId: this.state.holdingData.id })
            .then(res => {
                if (res.type === Strings.successType) {
                    console.log(res)
                    this.setState({ anvilUrl: res.data, isShowModal:true, loading: false })
                } else {
                    this.setState({ isShowComplete: true, loading: false, isShowModal:false })
                }
            })
    }

    changeStatus() {
        console.log(79)
        this.setState({ loading: true });
        this.props.updateDataByExpressInterestId({
            expressInterestId: this.state.holdingData.id,
            data: { memberSigned: 1, expressInterstStatus: 2 }
        }).then((res) => {
            if (res.type === Strings.successType) {
                this.setState({
                    isShowComplete: true,
                    loading: false
                })
            } else {
                this.setState({ loading: false });
            }
        })
    }

    //showing notification message
    showingMessage(type, message, title) {
        notification[type]({
            description: message,
        });
    }


    render() {
        return (
            <div className="opportunity-success-main">
                <div key={this.state.user ? this.state.user : null}>
                    <AppHeader history={this.props.history} user={this.state.user} />
                    {this.state.loading ? <Loading /> : null}
                </div>
                {this.state.isShowComplete ?
                    <div className="container-payment ani-left-to-right">
                        <h1 className="login-register-title-h investor-opportunity-title-h">Hello <span className="">{this.state.user ? (this.state.user.firstName + " " + this.state.user.lastName) : null}</span><br />Make The Right Investment</h1>
                        <div className="hloding-invester">
                            <h2 className="thankyou-h">Thank you, for expressing interest in {this.state.holdingData && this.state.holdingData.opportunityDetails && this.state.holdingData.opportunityDetails.portfolioCompanyName ? this.state.holdingData.opportunityDetails.portfolioCompanyName : null}.</h2>
                            <h3 className="thankyou-dec">Our operations team will get in touch with you shortly.</h3>
                            <div className="logo-text-sec" key={this.state.holdingData ? this.state.holdingData : null}>
                                <div className="logo-name row-logo-name">
                                    <div className="company-sec-div">

                                        {this.state.holdingData && this.state.holdingData.opportunityDetails && this.state.holdingData.opportunityDetails.logo && this.state.holdingData.opportunityDetails.logo.orginalUrl ? <img width="100" src={this.state.holdingData.opportunityDetails.logo.orginalUrl} alt={this.state.holdingData.opportunityDetails.logo.orginalUrl} />
                                            : <img src={cardImage} alt={cardImage}/>}
                                        <div className="card-text-sec">
                                            <div className="security-section">
                                                {this.state.holdingData && this.state.holdingData.opportunityDetails && this.state.holdingData.opportunityDetails.pricePerShare ?
                                                    <label style={{ fontWeight: "bolder" }}>No. of Shares:  </label> :
                                                    <label style={{ fontWeight: "bolder" }}>No. of Units:  </label>
                                                }

                                                <label> {this.state.holdingData ? this.state.holdingData.numberOfShares : 0}</label>

                                            </div>
                                            {this.state.holdingData && this.state.holdingData.opportunityDetails && this.state.holdingData.opportunityDetails.pricePerShare ?
                                                <div className="security-section">
                                                    <label style={{ fontWeight: "bolder" }}>Price Per Share: </label> <label> ${this.state.holdingData && this.state.holdingData.opportunityDetails ? this.state.holdingData.opportunityDetails.pricePerShare : 0}</label>

                                                </div> :
                                                <div className="security-section">
                                                    <label style={{ fontWeight: "bolder" }}>Price Per Unit: </label> <label> ${this.state.holdingData && this.state.holdingData.opportunityDetails ? this.state.holdingData.opportunityDetails.pricePerUnit : 0}</label>

                                                </div>
                                            }
                                            {this.state.holdingData && this.state.holdingData.opportunityDetails && this.state.holdingData.opportunityDetails.carriedInterest ?
                                                <div className="security-section">
                                                    <label>Carry : {this.state.holdingData.opportunityDetails.carriedInterest}%</label>
                                                </div> : null
                                            }
                                            {this.state.holdingData && this.state.holdingData.opportunityDetails && this.state.holdingData.opportunityDetails.primaryDetails && this.state.holdingData.opportunityDetails.primaryDetails.length > 0 ?
                                                <div className="security-section">
                                                    {this.state.holdingData.opportunityDetails.primaryDetails.map((item) => {
                                                        return (
                                                            <div className="security-section">
                                                                <label style={{ fontWeight: "bolder" }}>{item.key} </label>
                                                                <label>{item.value}</label>
                                                            </div>

                                                        )
                                                    })}

                                                </div> : null}

                                            <div className="security-section">
                                                <label style={{ fontWeight: "bolder" }}>Invested On: </label><label> {this.state.holdingData ? moment(new Date(this.state.holdingData.createdAt)).format('MM/DD/YYYY') : null}</label>
                                            </div>
                                            <div className="security-section total-value">
                                                <label style={{ fontWeight: "bolder" }}>Total Value: </label> <label> ${this.state.holdingData ? (this.state.holdingData.transactionVolume.toLocaleString('USD')) : 0}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <h4 className="thankyou-p">Please wait. You are being redirected to <span  onClick={() => { this.props.history.push('/my-holdings') }}>My holdings</span></h4> */}
                        </div>
                    </div> :
                    <>
                    {this.state.isShowModal ? 
                    <AnvilSignatureFrame
                        signURL={this.state.anvilUrl}
                        scroll="smooth"
                        onFinishSigning={(payload) => {
                            console.log(180, payload)
                            if (payload && payload.action === "signerComplete") {
                                this.changeStatus()
                            }
                        }}
                        onError={(errorPayload) => console.log(181, errorPayload)}
                    />: null}</>}
                <Footer history={this.props.history} />
            </div>
        )

    }
}

export default connect(null, { getUserDataLean, generateSubscriptionAgreementUrl, updateDataByExpressInterestId })(OpportunitySuccess);
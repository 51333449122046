import React, { Component } from 'react';
import { Card, Button, Row, Col, notification } from 'antd';

import cardImage from '../../assets/images/cardImage.png';
import { AppHeader, Footer } from '../../Components';
import '../../App.css';
import './InvesterOpportunities.css';
import { connect } from 'react-redux';
import { getUserDataLean, getActiveOpportunitiesLean } from '../../Redux/Crud';
import { Strings } from '../../Constants/Strings';
import InfiniteScroll from 'react-infinite-scroller';
import { ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';


const { Meta } = Card;

class InvesterOpportunities extends Component {
    constructor(props) {
        super(props)

        this.state = {
            disabled: false,
            allOpportunities: [],
            totalCount: 0,
            hasMore: true,
            user: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserDetails()
        // this.loadDatas = this.loadData.bind(this)
    }

    //getting user data
    getUserDetails() {
        if (localStorage.getItem('userData')) {
            let user = JSON.parse(localStorage.getItem('userData'));
            this.props.getUserDataLean(user.id)
                .then(res => {
                    if (res.type === Strings.successType) {
                        if (res.data && res.data.userType && res.data.userType === 2) {
                            this.setState({
                                user: res.data
                            })
                        } else {
                            localStorage.clear();
                            this.props.history.push('/');
                        }
                        if (res.data && res.data.isVerified) {
                            // this.loadDatas = this.loadData(0);
                        } else {
                            this.props.history.push('/profile');
                            this.showingMessage(Strings.errorType, Strings.profileNotVerificationError, Strings.profileTitle)
                        }
                    } else {
                        localStorage.clear();
                        this.props.history.push('/');
                    }
                })
        } else {
            localStorage.clear();
            this.props.history.push('/');
        }
    }

    //selecting shares
    handleSliderChange(e, ind) {
        let opportunities = JSON.parse(JSON.stringify(this.state.allOpportunities));
        opportunities[ind]['selectedShares'] = e;
        this.setState({
            allOpportunities: opportunities
        })
    }

    //shares buying
    handleNavigation(item) {
        if (item && item.selectedShares && item.selectedShares > 0) {
            this.props.history.push('/investor-types', { shareData: item })
        } else {
            this.showingMessage(Strings.errorType, Strings.notSelectedSharesError, Strings.opportunityTitle)
        }
    }

    //showing notification message
    showingMessage(type, message, title) {
        notification[type]({
            description: message,
        });
    }

    //get opportunities on scroll
    loadData = (val) => {
        if(val){
            let page = val -1
        let limit = Strings.noOfOffersPerPage;
        let skip = page * limit;
        this.props.getActiveOpportunitiesLean({
            searchValue: "",
            skip: skip,
            limit: limit
        }).then(res => {
            if (res.type === Strings.successType) {
                let hasMore = false
                let allOpportunities = this.state.allOpportunities.concat(res.data.data);
                if (page > (res.data.totalCount / limit)) {
                    hasMore = false;
                } else {
                    hasMore = true;
                }
                console.log(hasMore)
                allOpportunities.map(item => {
                    item.selectedShares = 100;
                    return item;
                })
                this.setState({
                    allOpportunities: allOpportunities,
                    totalCount: res.data.totalCount,
                    hasMore: hasMore
                });
            }
        });
    }
    }

    render() {
        return (
            <div className="invester-opp-main">
                <div key={this.state.user ? this.state.user : null}>
                    <AppHeader history={this.props.history} user={this.state.user} />
                </div>

                <div className="container-invester  ani-top-to-bottom">
                    <h1 className="login-register-title-h investor-opportunity-title-h">Hello <span className="">{this.state.user ? (this.state.user.firstName + " " + this.state.user.lastName) : null}</span><br />Make The Right Investment</h1>
                    <h1 className="holding-details-section-card-h opportunity-h"  >Opportunities</h1>
                    <div className="filters-section">
                    </div>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={(val)=>{this.loadData(val)}}
                        hasMore={this.state.hasMore}
                        loader={<div key={0}></div>}
                    >

                        <Row className="investor-row" gutter={32}>
                            {this.state.allOpportunities.length > 0 ?
                                <>
                                    {this.state.allOpportunities.map((item, ind) => {
                                        return <Col sm={20} md={12} lg={8} key={ind}>
                                            <Card
                                                className="investor-opp-card">
                                                <div className="investor-opp-card-title" >
                                                    <div className="hover-section" onClick={() => { this.props.history.push('/deal-investment/:' + item.portfolioCompanyName + '?/:' + item.id) }}>
                                                        <ArrowRightOutlined />
                                                    </div>
                                                    <div className="img-section">
                                                        <Meta
                                                            avatar={item.logo && item.logo.orginalUrl ? <img src={item.logo.orginalUrl}  alt={item.logo.orginalUrl}/>
                                                                : <img src={cardImage} alt={cardImage}/>}
                                                            onClick={() => { this.props.history.push('/deal-investment/:' + item.portfolioCompanyName + '?/:' + item.id) }}
                                                        />
                                                    </div>
                                                    <div className="holding-card-content opportunity-card-content">
                                                        <h4 className="company-name">{item.portfolioCompanyName} <span className="opportunity-category"> {item.companyDetails.category ? ' | ' + item.companyDetails.category : null}</span> {item.companyDetails.founded ? ' | ' + moment(new Date(item.companyDetails.founded)).format('YYYY') : null}</h4>

                                                        {item && item.primaryDetails && item.primaryDetails.length > 0 ?
                                                            item.primaryDetails.map((item) => {
                                                                return (
                                                                    <div className="security-section">
                                                                        <label>{item.key} </label>
                                                                        <label>{item.value}</label>
                                                                    </div>

                                                                )
                                                            })
                                                            : null}
                                                        <div className="invest-button-sec">
                                                            <Button type="primary" onClick={() => { this.props.history.push('/deal-investment/:' + item.portfolioCompanyName + '?/:' + item.id) }}>

                                                                {this.state.user && this.state.user.verifyStatus === 2 ? 'Learn more' : 'Learn more'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {item.isFullySubscribed ? <div className='fully-subscribed'>Fully Subscribed</div> : null}
                                                </div>

                                            </Card>
                                        </Col>
                                    })}</>
                                :
                                <h4 className="no-holdings">
                                    Please wait while we retrieve Active opportunities.
                                </h4>
                            }
                        </Row>
                    </InfiniteScroll>
                </div>
                <Footer history={this.props.history} />
            </div>

        )
    }
}

//Exporting login page
export default connect(null, { getUserDataLean, getActiveOpportunitiesLean })(InvesterOpportunities);
